<template>
  <div class="information">
    <h3 class="sub-title">Friendly Reminder</h3>
    <div class="container">
      <h4>
        Payment
      </h4>
      <p>
        If you need to pay, you can pay with Master card or Visa card in Wuhan and please exchange some RMB cash before you leave.
      </p>
      <h4>
        Transportation
      </h4>
      <p>
        From Wuhan Tianhe Airport: Take Metro Line 2 at Wuhan Tianhe Airport, transfer to Line 6 at Changqing Garden Station, get off at the North Station of the National Expo Center (exit D), and walk about 15-20 minutes to the hotel.
      </p>
      <p>
        From Wuhan railway Station: Take Metro Line 4 at Wuhan railway Station, transfer to Line 6 at Zhongjia village Station, get off at the North Station of the National Expo Center (exit D), and walk about 15-20 minutes to the hotel.
      </p>
      <p>
        From Hankou railway Station: Take Metro Line 4 at Hankou railway Station, transfer to Line 6 at Jianghan Road Station, get off at the North Station of the National Expo Center (exit D), and walk about 15-20 minutes to the hotel.
      </p>


    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .container {
    font-family: "PublicSans Regular";
    font-size: 16px;
    h4 {
      font-family: "Akrobat Black";
      font-weight: 700;
      position: relative;
      margin: 15px 0 15px;
      padding-left: 15px;
      border-left: 3px solid #19317e;
      font-size: 20px;
      line-height: 28px;
    }
    p {
      margin-bottom: 20px;
      text-indent: 2em;
    }
    .img-box {
      margin: 0 auto;
      max-width: 600px;
      width: 80%;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin-bottom: 20px;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
